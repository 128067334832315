import { render } from "./AppInformation.vue?vue&type=template&id=09669808&scoped=true"
import script from "./AppInformation.vue?vue&type=script&lang=ts"
export * from "./AppInformation.vue?vue&type=script&lang=ts"

import "./AppInformation.vue?vue&type=style&index=0&id=09669808&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-09669808"

export default script
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QBtn,QForm,QCard,QCardSection,QInput,QAvatar,QToggle});
