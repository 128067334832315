
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IApp } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import AppForm from '@/components/app/AppForm.vue'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { UserModel } from '@/models/user-model'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: {
    AppForm,
    TagContent,
  },
  directives: { maska },
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class AppInformation extends mixins(AppMixin, BaseFormMixin) {
  selectingApp: IApp = {}

  get requiredRule() {
    const requiredRule = {
      appName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.app_name'),
      }),
      chanelAccessToken: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_access_token'),
      }),
      chanelSecret: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_secret'),
      }),
      chanelAccessTokenTest: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_access_token_test_mode'),
      }),
      chanelSecretTest: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.chanel_secret_test_mode'),
      }),
    }
    return requiredRule
  }

  get isNew() {
    return !this.selectingApp?._id
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get webhookURL() {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/callback/${this.selectingApp.app_prefix}`
    return url
  }

  get webhookTestURL() {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/callback/test/${this.selectingApp.app_prefix}`
    return url
  }

  get lineProfileURL() {
    const url = `https://page.line.me/?accountId=${this.selectingApp.account_id}&openQrModal=true`
    return url
  }

  get lineProfileTestURL() {
    const url = `https://page.line.me/?accountId=${this.selectingApp.account_id_test}&openQrModal=true`
    return url
  }

  get liffWebhookURL() {
    const baseUrl = process.env.VUE_APP_API_ENDPOINT
    const url = `${baseUrl}webhook/liff-app?app_id=${this.selectingApp._id}`
    return url
  }

  getQRCode(account_id) {
    const url = `https://qr-official.line.me/sid/m/${account_id}.png`
    return url
  }

  async fetchSelectingApp() {
    if (!this.appId) {
      this.selectingApp = {}
      return
    }

    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.appId)

    this.parentForm = this.selectingApp
    this.initForm = cloneDeep(this.parentForm)
  }

  @Watch('appId', { immediate: true })
  appIdChanged() {
    // [AppMixin]
    this.fetchSelectingApp()
  }

  onCancel() {
    return true
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let success = false
    if (this.isNew) {
      success = await dispatch(ACTION_APP.ADD_NEW, {
        ...this.selectingApp,
      })
    } else {
      success = await dispatch(ACTION_APP.UPDATE, {
        _id: this.selectingApp._id,
        ...this.selectingApp,
      })
    }

    if (success) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
  }
}
